









































































































import Vue from 'vue';
import axios from 'axios';
import LanguageSelector from '@/components/LanguageSelector.vue';

// eslint-disable-next-line import/no-cycle

export default Vue.extend({
  components: {
    LanguageSelector,
  },
  data() {
    return {
      iniData: [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ],
      series: [
        {
          data: [
            { x: 'Title', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
            { x: 'D', y: [0, 0] },
          ],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'rangeBar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
      },
      overviewSeries: [{
        name: '我的平均凝視時間(ms)',
        data: [4200, 5500, 4100],
      }, {
        name: '班級平均凝視時間(ms)',
        data: [3300, 4200, 3300],
      }],
      overviewChartOptions: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: ['Not Detected', '題目', '答案選項'],
        },
      },
      code: '',
      answerGroupId: '',
      sourceQuizGroupId: '',
      currentNum: 0,
      list: null as any,
      quizTitle: '',
      items: { details: [{ area: 'Total', Used_Time: 0 }] } as any,
      answerGroupData: null as any,
      answerList: null as any,
      overallConcentrationScore: 0,
      averTime: null as any,
      isLoading: true,
      hasLoadingError: false,
    };
  },
  async created() {
    this.answerGroupId = this.$route.query.answerGroupId as string;
    // this.answerGroupId = '398fe4eb-c386-4538-9f1a-cac857a26005';
    try {
      const res = await axios.get(`https://eye-tracking-api.qsticker.net/v1/tracking/analysis?answerGroupId=${this.answerGroupId}`);
      const cleanedDataString = res.data.replace(/NaN/g, 'null');
      const analysisRes = JSON.parse(cleanedDataString);

      // 直接從 res.data 提取需要的值
      this.quizTitle = analysisRes.sourceQuizGroupName;

      this.code = analysisRes.shortId;
      this.answerGroupId = analysisRes.answerGroupId;
      this.sourceQuizGroupId = analysisRes.sourceQuizGroupId;
      this.overallConcentrationScore = analysisRes.overallConcentrationScore;
      this.list = analysisRes.list;

      const { averTime } = analysisRes;
      this.averTime = averTime;

      // 使用 list 中的 sequence 資料來更新圖表數據
      const tempChartData = this.iniData;
      analysisRes.list[this.currentNum].sequence.forEach((element : any) => {
        tempChartData.push({ x: element[0], y: [element[1], element[2]] });
      });

      this.series = [{ data: tempChartData }];
      this.setAnswerGroup();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.hasLoadingError = true;
    }
    // try{
    //   const analysisQueryResult = JSON.parse(JSON.stringify(await TrackingApi.getAnalysis(this.answerGroupId)));
    // }
  },
  methods: {
    setAnswerGroup() {
      axios
        .get(`https://api.qsticker.net/service/v1/answer-group/${this.answerGroupId}`)
        .then((res) => {
          console.log(res.data);
          console.log(res.data.quizEventId);
          this.answerGroupData = res.data;
          const tempList: { score: any; userAnswer: any; correctAnswer: any; }[] = [];
          this.list.forEach((element: any) => {
            this.answerGroupData.answerList.forEach((agEle: any) => {
              if (agEle.uuid === element.answerUuid) {
                let myAnswer = '';
                agEle.userAnswer.forEach((i : any) => {
                  myAnswer = `${myAnswer + i} `;
                });

                let correctAnswer = '';
                agEle.sourceQuiz.answerSet.forEach((i: any) => {
                  correctAnswer = `${correctAnswer + i} `;
                });
                tempList.push({ score: agEle.score, userAnswer: myAnswer, correctAnswer });
              }
            });
          });
          this.answerList = tempList;
        });
      setTimeout(() => {
        this.handleUsageData();
        this.handleOverviewChart();
      }, 200);
    },
    handleOverviewChart() {
      if (this.$i18n.locale === 'en') {
        this.overviewSeries[0].name = 'My average gaze time (ms)';
        this.overviewSeries[1].name = 'Class average gaze time (ms)';
        this.overviewSeries[0].data = [this.averTime.studentNotDetectedTime, this.averTime.studentTitleTime, this.averTime.studentChoicesTime];
        this.overviewSeries[1].data = [this.averTime.groupNotDetectedTime, this.averTime.groupTitleTime, this.averTime.groupChoicesTime];
      } else if (this.$i18n.locale === 'zh-TW' || this.$i18n.locale === '') {
        this.overviewSeries[0].name = '我的平均凝視時間(ms)';
        this.overviewSeries[1].name = '班級平均凝視時間(ms)';
        this.overviewSeries[0].data = [this.averTime.studentNotDetectedTime, this.averTime.studentTitleTime, this.averTime.studentChoicesTime];
        this.overviewSeries[1].data = [this.averTime.groupNotDetectedTime, this.averTime.groupTitleTime, this.averTime.groupChoicesTime];
      }
    },
    handleUsageData() {
      const tempList = [];
      const listJson = JSON.parse(JSON.stringify(this.list[this.currentNum]));
      // if (this.$i18n.locale === 'en') {
      tempList.push({ Area: 'All', GazeTime: `${listJson.totalUsedTime} ms`, Frequency: '' });
      listJson.time.forEach((element: any) => {
        tempList.push({ Area: element.area, GazeTime: element.usedTime, Frequency: element.visitFrequency });
      });
      // } else if (this.$i18n.locale === 'zh-TW' || this.$i18n.locale === '') {
      //   tempList.push({ 區域: '全部', 凝視停留時間: `${listJson.totalUsedTime} ms`, Frequency: '' });
      //   listJson.time.forEach((element: any) => {
      //     tempList.push({ 區域: element.area, 凝視停留時間: element.usedTime, Frequency: element.visitFrequency });
      //   });
      // }

      this.items = tempList;
      this.$set(this.items, 'details', tempList);
    },
    refreshChart() {
      this.series = [{ data: [] }];
      setTimeout(() => {
        console.log();
      }, 100);
      const tempChartData = [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ];
      (JSON.parse(JSON.stringify(this.list[this.currentNum]))).sequence.forEach((element : any) => {
        tempChartData.push({ x: element[0], y: [element[1], element[2]] });
      });
      this.series = [{ data: tempChartData }];
    },
    preBtnClicked() {
      if (this.currentNum > 0) {
        this.currentNum -= 1;
        this.refreshChart();
        this.handleUsageData();
      }
    },
    nextBtnClicked() {
      if (this.currentNum < this.list.length - 1) {
        this.currentNum += 1;
        this.refreshChart();
        this.handleUsageData();
      }
    },
  },
});
