





















import Vue from 'vue';

export default Vue.extend({
  props: {
    bgcolor: {
      type: String,
      required: false,
      default: 'outline-secondary',
    },
    contentcolor: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: Boolean,
      required: false,
      default: true,
    },
    clienttype: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      langs: [
        {
          label: '繁體中文',
          code: 'zh-TW',
        },
        {
          label: 'English',
          code: 'en',
        },
        {
          label: 'ไทย',
          code: 'th',
        },
      ],
    };
  },
  beforeCreate() {
    if ((this.$route.query.language !== '') && (this.$route.query.language != null) && (this.$route.query.language !== undefined)) {
      this.$i18n.locale = this.$route.query.language as string;
    } else {
      this.$i18n.locale = 'zh-TW';
    }
  },
});
