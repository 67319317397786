






import Vue from 'vue';
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
// eslint-disable-next-line import/no-cycle

export default Vue.extend({
  data() {
    return {
      answerGroupId: '',
      series: [{
        name: '我的平均凝視時間(ms)',
        data: [4200, 5500, 4100],
      }, {
        name: '班級平均凝視時間(ms)',
        data: [3300, 4200, 3300],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: ['Not Detected', '題目', '答案選項'],
        },
      },
    };
  },
  methods: {
  },
});
